import React from "react";
import "./Home.css";
import GoogleApiWrapper from './googleMap'
import {Card,CardGroup} from "react-bootstrap"

// import {Map,GoogleApiWrapper, Marker} from 'google-maps-react';
import { render } from "@testing-library/react";

const Block = function (props) {
  const {child, id, bgColor, padding} = props
  const styles = {
    container: {
      width: "100%",
      margin:'0px 0 0',
      padding: padding || '30px 0',
      backgroundColor: props.bgColor,
    }
  }

  return (
    <div id={id} className="slide" style={styles.container}>
      {/* <h2 style={{ paddingTop: "100px" }}></h2> */}
      {child}
    </div>
  )

}

const Inform = () => {
  return (
    <div className="container" style={{ color: 'black', fontFamily: 'sans-serif', textAlign:'center', marginTop:'-30px'  }}>
             
          <p className="info" style={{margin:'auto', marginTop:'10px', marginBottom:'13px'}}>        
            Security solutions from PSDL</p>
          <p className="secu"> Growing Concerns of Connected Things</p>
          <p className="line"></p>          
          <div className="row" >
            <div className="col-sm-4">
              <div className="card.noline">
                <img src="images/1.jpg" className="box"/>
                <div className="card-body">
                  <p className="sp">
                    Hacking cases are rapidly increasing due<br />
                    to the advent of digital society based on<br />
                    ICT technology and social network services.
                  </p>
                </div>
              </div>             
            </div>
            <div className="col-sm-4">
              <div className="card.noline">
                <img src="images/2.jpg" className="box"/>
                <div className="card-body">
                  <p className="sp">
                  Security is increasingly important in the era<br />
                  of the 4th Industrial Revolution as<br />
                  the Internet of Things, big data, and artificial<br />
                  intelligence technologies are combined.
                  </p>
                </div>
              </div>             
            </div>
            <div className="col-sm-4">
              <div className="card.noline">
                <img src="images/3.jpg" className="box"/>
                <div className="card-body">
                  <p className="sp">
                  Increased communication between<br />
                  IoT devices and servers, such as<br />
                  smart cities, smart factories,<br />
                  smart homes, and self-driving cars.
                  </p>
                </div>
              </div>             
            </div>          
          </div>

          <div className="row" marginTop="70px">          
            <div className="col-sm-6">
              <div className="card.noline" >
                <img src="images/4.jpg" className="box"/>
                <div className="card-body">
                  <p className="sp" >
                  Generalization of data transmission<br />
                  over wireless networks.
                  </p>
                </div>
              </div>             
            </div>
            <div className="col-sm-6">
              <div className="card.noline">
                <img src="images/5.jpg" className="box"/>
                <div className="card-body">
                  <p className="sp">
                  Pradigm shift to a security system<br />
                  based on End-to-End Encryption<br />
                  for a robust security system.
                  </p>
                </div>
              </div>             
            </div>        
          </div>
          <p className="line" ></p>
          <p className="secu">We lift up the Security concerns of Things Manufactures
          <br />with our own End to End Security Technology and Solutions.</p>
      </div>
  
  )   
}

const Technology = () => {
  let style = {
    mTop: {

      marginTop: '30px',
      borderRadius: '25px',
      background: 'white',
      padding: '20px',
      width: '100%',      
      height: '150px',
      margin: '5px'
    },
    stRow: {
      marginRight: '0px'
    },
    top: {
      padding: "20px",
      backgroundColor:"#b4d0c6",
      color:"white",      
    }, 
    top1: {
      padding: "20px 0",
      backgroundColor:"#b4d0c6",
      color:"white",      
      marginTop:"30px"
    },
    boxline:{
      border: "0px solid rgba(255,255,255,.5)",
      borderRadius: "10px",
      backgroundColor: "white",
      color: "gray",
      margin: "10px",
      textAlign: "left",
      fontWeight:"500"
        }
    
    
  }

  return (
    <div className="container" >
      {/* <h2><span style={{fontWeight: '900'}}>SOCIUM<sup>®</sup></span> Kits 구성품</h2> */}
      <h2>TECHNOLOGY</h2>
      <p className="slash">ㅡ</p>
      <p className="techbox">Random number generation algorithm</p><br /><br />
      <img src="/images/tech1.png" width="100%" />
      <p className="line2"></p>
      <p className="techbox" >End-to-End encryption</p><br /><br />
      <img src="/images/tech2.png" width="100%" />
      <br /> <br/>
      <CardGroup>
        <Card style={style.boxline}>
          <Card.Body>       
            <Card.Text>
            Algorithm for extracting high-quality physical
            random numbers from electrical signals in IoT devices
            </Card.Text>
          </Card.Body>             
        </Card>

        <Card style={style.boxline}>
          <Card.Body >       
            <Card.Text>
            Development of lightweight encryption and decryption
            system based on international cryptographic system
            </Card.Text>
          </Card.Body>             
        </Card>
      </CardGroup>

      <CardGroup>
        <Card style={style.boxline}>
          <Card.Body>       
            <Card.Text > 
            High-quality, high-speed physical random number and cryptographic 
            key generation technology based on radio communication signals
            </Card.Text>
          </Card.Body>             
        </Card>

        <Card style={style.boxline}>
          <Card.Body>       
            <Card.Text>
            Encryption, decryption real-time refresh system
            for End-to-End data secure transmission
            </Card.Text>
          </Card.Body>             
        </Card>        
      </CardGroup>  
</div>

  )
}

const Sol = () => {
  let style = {
    mTop: {
      padding: "20px 0",
      marginBottom: "20px",
      fontFamily:'Roboto',
      marginTop: "20px"
   },
   textBox: {
    backgroundColor: "#f0efef",
    borderRadius: "5px",
    textAlign: "center",
    fontfamily:'Roboto'
   }
  }

  return (
    <div className="container" style={{ textAlign: 'center', color: 'black' }}>
      {/* <h2><span style={{fontWeight: '900'}}>SOCIUM<sup>®</sup></span> Kits 구성품</h2> */}
      <h2>SOLUTION</h2>
      <p className="slash">ㅡ</p>

      <div className="row">
        <div className="col-md-6" >
          <h4 style={style.mTop}>Security IC</h4>
          <div style={{margin:"10px",boxShadow:"0px 0px 3px 3px lightgray", padding:"15px"}}>       
          <img src="/images/sol_1.png" width="80%" className="sol1" />     
          <p className="line"></p>
          <li className="sol">PSDL Securiy IC and S/W Solution provide the End to End Security solution for IoT, Audio and Video Applications.</li>
          <br />
          <li className="sol">Customer Samples will be Available by end of 2021.</li>   <br />             
       
          </div>          
        </div>       


        <div className="col-md-6">
        <h4 style={style.mTop}>Security Module</h4> 
          <div style={{margin:"10px",boxShadow:"0px 0px 3px 3px lightgray", padding:"15px"}}>
          <img src="/images/sol_3.png" width="70%" className="sol2"/>
          <br /><br />
          <p className="line"></p>
          <li className="sol">Before the Security IC comes out, the Security Module with Firmware solution for End to End Security could be provided.</li>
          <br />
          <li className="sol">Customer Samples will be Available shortly.</li><br />
       
          </div>         
        </div>
      </div>

      <br />
    </div>
  )
}

const About = () => {
  let style = {
      box :{
        padding: "10px",
        border: "none",
        backgroundColor:"transparent"
      }
     

    }
    
  

  return (
    <div className="container" style={{ textAlign: 'center', color: 'black' }}>
      {/* <h2><span style={{fontWeight: '900'}}>SOCIUM<sup>®</sup></span> Kits 구성품</h2> */}
      <h2>VISION AND MISSION</h2>
      <p className="slash">ㅡ</p>
      <p className='vision' style={{marginBottom:"80px"}}>Our vision is to develop all products and services that can be <br/>
      connected to the future wireless network with a focus on the core value of security.
      </p>      
        
      <CardGroup >
            <Card style={style.box}>
              <Card.Img    src="images/vision01.png" />                   
            </Card>
      </CardGroup>
     
      <br />
      <p className="line2" style={{marginBottom:"80px"}}></p>
      <p className='vision' style={{marginBottom:"80px"}}>
        Our company possesses secure IC technology and security software technology <br/>
        between small and lightweight devices, and uses this technology to provide <br/>
        smart secure products and services such as the Internet of Things, <br/>
        autonomous vehicles, drones, smart factories, smart wearables, and smart delivery.</p>
      <CardGroup >
            <Card style={style.box}>
              <Card.Img    src="images/vision02.png" />                   
            </Card>
      </CardGroup>
      <br/><br/>
      <CardGroup >
            <Card style={style.box}>
              <Card.Img    src="images/vision03.png" />                   
            </Card>
      </CardGroup>
      <br/><br/><br/>
    </div>
  )
}

const Contact = () => {
  let style = {
    mTop: { padding: "20px 0", textAlign: 'center' },
    box :{
      color: "white",
      marginRight: "auto",
      marginLeft:"auto", 
      border:"none solid rgba(255,255,255,.5)",
      borderRadius:"10px",
      width: "100%",      
      padding:"10px"
      
    }
    }

  return (
    // <div className = "bg-cover">
    <div className="bg-cover2" >
 

      <div className="container" style={{ color: 'black', margin:'auto', }}>
        {/* <h2><span style={{fontWeight: '900'}}>SOCIUM<sup>®</sup></span> Kits 구성품</h2> */}
        <h2 style={{ color: "white", textAlign:"center"}}>CONTACT US</h2>
        <p style={{textAlign:"center", color:"white", fontWeight:"bold"}}>ㅡ</p>
        {/* <div  className="row" style={style.box}> */}
        <div  className="row" style={style.box}>
        
          <div className="col-sm-2" style={{textAlign:"center", marginBottom:"7px"}}>     
            <img src="/images/icon3.png " width="30px" hspace="10" />           
            </div>
               <div className="col-sm-10" >                   
               Information Security Cluster 4th 466, Pangyo Second TechnoValley 815,Daewangpangyo-ro, Sujeong-gu, Seongnam-si, Gyeonggi-do, Republic of Korea          
             </div>
             </div>
             <p className="line1"></p> 
             <div className="row" style={style.box}>
                
             <div className="col-sm-2" style={{textAlign:"center"}}>     
             <img src="/images/icon1.png " width="30px" hspace="10"  />
               </div>
               <div className="col-sm-10">                   
               sales@psdl.co.kr  
           
             </div>
             </div>
             <p className="line1"></p> 
             <div  className="row" style={style.box} >
             <div className="col-sm-2"  style={{textAlign:"center", marginBottom:"10px"}}>     
             <img src="/images/icon2.png" width="30px" hspace="10" />
               </div>
               <div className="col-sm-10" >                   
               031-984-7027
           
             </div>
             </div>


        <div style={{ color: "white", textAlign: "left"}}>
        </div>
        <GoogleApiWrapper/>       
      </div>
    </div>     
  )
}



export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
    this.setActive = this.setActive.bind(this)
    
  }

  setActive(arr) {
    // console.log(arr)
    let posId = null
    for (let i = 0; i < arr.length; i++) {
      let rect = arr[i].getBoundingClientRect()
      // console.log(arr[i].id, rect.top, rect.right, rect.bottom, rect.left);
      if
        (rect.top > 90 && rect.top < 400) posId = arr[i].id
    }

    if (posId != null) {
      let navLinks = document.getElementsByClassName('nav-link')
      for (let i = 0; i < navLinks.length; i++) {
        if (navLinks[i].href.split('#')[1] === posId) {
          // console.log(navLinks[i].href.split('#')[1], posId)
          navLinks[i].classList.add('active')
        } else {
          navLinks[i].classList.remove('active')
        }
      }
    }
  }

  componentDidMount() {
    // setTimeout(()=>this.setState({loaded: true}), 2000)
    this.setState({ loaded: true })
    let that = this
    setInterval(() => {
      let arr = document.getElementsByClassName('slide')
      that.setActive(arr)
    }, 200)
  }
  render() {
    const jumboH = 780
    const myStyle = {
      jumbo: { height: jumboH + "px" },
      text: { marginTop: jumboH / 6 + "px"},
      text1: { marginLeft:"auto", marginRight:"auto"}
    }
    return (
      <>
        <div className="jumbotron bg-cover" style={myStyle.jumbo}>
    
          <div style={myStyle.text} className ="container">
          <h1>Welcome to PSDL</h1>
          <div style={myStyle.text1}>          
            <p>We develop end-to-end encryption based security technology for a strong security system.</p>        
          
          
          </div>
          </div>
        </div>

        <div className="content">

          <Block child={<Inform />} bgColor='white' />
          <Block id="technology" child={<Technology />}  bgColor='#edf2f0' />
          <Block id="solution" child={<Sol />} bgColor='white' />
          <Block id="about" child={<About />} bgColor='#edf2f0' />
          <Block id="contact" child={<Contact />} bgColor="white" padding="0 0"/>       

        </div>
      </>
    );
  }
}
