import React, {Component} from 'react';

// class googleMap extends React.Component {
//     render(){
//         return(
//             <div>
//                 <h2>Content</h2>
//                 <p>Hey!</p>
//             </div>
//         )    
//     };
// }
// export default googleMap;



import { Map, GoogleApiWrapper,Marker } from 'google-maps-react';
import { render } from '@testing-library/react';

class googleMap extends Component {
    
    
    
    
    render() {
        const mapStyles = {
            // width: '1100px',
            // height: '500px',
            width: '100%',
            height: '350px',
            position :'relative',
        
        };
        return (
        <div style={{position:"relative" }}>
            <Map
                
                // containerElement={
                //     <div style={{  position :'relative'}} />
                // }
                // loadingElement={<div style={{ position :'relative' }} />}

                google={this.props.google}
                onClick={this.onEventChecker}
                zoom={16}
                style={mapStyles}
                initialCenter={{ lat: 37.413071, lng: 127.099546 }}
            >
           <Marker position={{ lat: 37.413071, lng: 127.099546}} />
           </Map>
        </div>    
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyASMnod3soXE3-PJYfe7NZhsOSzPG8rjrs',
})(googleMap);
